import React, { useState, useEffect } from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import { Navigation } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import TextTruncate from "react-text-truncate"

import "swiper/css"
import "swiper/css/navigation"
import "../styles/sidebar.scss"
import {GatsbyImage, StaticImage} from "gatsby-plugin-image";
import Testimonials from "./blocks/Testimonials";

export default function Sidebar({ search }) {
  const [instagram, setInstagram] = useState('');
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    fetch(`${process.env.GATSBY_WP_URL}/wp-json/instagram/v1/feed`).then(result => {
      return result.json()
    }).then(text => {
      setInstagram(text)
      // console.log('insta', text)
    })
  }, []);

  return (
    <StaticQuery
      query={graphql`
        query SidebarQuery {
          wp {
            themeGeneralSettings {
              themeSettings {
                social {
                  title
                  links {
                    icon {
                      altText
                      publicUrl
                      sourceUrl
                    }
                    link {
                      target
                      title
                      url
                    }
                  }
                }
              }
            }
          }
          allWpPost(
            sort: { fields: [date], order: DESC }
            limit: 5
          ) {
            nodes {
              uri
              date(formatString: "MMMM DD, YYYY")
              title
              featuredImage {
                node {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
            }
          }
          allWpTestimonial {
            nodes {
              title
              content
              slug
              featuredImage {
                node {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={data => {
        const options = data?.wp?.themeGeneralSettings?.themeSettings
        const posts = data?.allWpPost?.nodes

        const testimonials = data?.allWpTestimonial?.nodes
        return (
	        <aside className="sidebar-news">
            <div className="podcast">
              <iframe width="560" height="315" src="https://www.youtube.com/embed/nR1NJ_OmLnE?si=M0wyU1GhhcIy1_6d" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
              <a href="https://tinyurl.com/HTP-EP1" target="_blank">Listen to the Helicopter Training Podcast</a>
            </div>
            <h4 className="instagram-logo-header">
              <Link to={"https://www.instagram.com/" + instagram.username} target="_blank" className="instagram-link">
                <StaticImage src="../images/instagram-logo.png" alt="Instagram Fly Bend" />  flybend
              </Link>
            </h4>
            <div className="instagram-wrapper desktop">
              {instagram?.posts && instagram?.posts.slice(0, 9).map(instagramPhoto => {
                return (
                  <Link to={"https://www.instagram.com/" + instagram.username} target="_blank" className="instagram-link">
                    <img alt="Instagram Fly Bend" className="instagram-image" src={instagramPhoto.thumb} loading="lazy" />
                  </Link>
                )
              })}
            </div>
            <div className="instagram-wrapper mobile">
              <Swiper
                spaceBetween={18}
                slidesPerView={3}
              >
                {instagram?.posts && instagram?.posts.map(instagramPhoto => {
                  return (
                    <SwiperSlide>
                      <div className="slide-wrap">
                        <img alt="Instagram Fly Bend" src={instagramPhoto.thumb} loading="lazy" />
                      </div>
                    </SwiperSlide>
                  )
                })}
              </Swiper>
            </div>
            <div className="social-links">
              <h3>{options?.social?.title}</h3>
              <ul>
                {options?.social?.links && options?.social?.links.map(link => {
                  return (
                    <li>
                      <Link to={link?.link?.url} target={link?.link?.target}>
                        <img src={link?.icon?.sourceUrl} alt={link?.icon?.altText} loading="lazy" />
                      </Link>
                    </li>
                  )
                })}
              </ul>
            </div>
            <div className="search-bar">
              <form action="/search/">
                <input name="s" type="search" placeholder="search news..." />
                <button>
                  <StaticImage src="../images/search-icon.png" />
                </button>
              </form>
            </div>
            <div className="testimonials-slider">
              <Swiper
                spaceBetween={0}
                slidesPerView={1}
                modules={[Navigation]}
                navigation
              >
                {testimonials.map(testimonial => {
                  return (
                    <SwiperSlide
                    >
                      <div className="testimonial-block">
                        <div className="featured-image">
                          <GatsbyImage className="image-container" alt="image" image={testimonial?.featuredImage?.node?.localFile.childImageSharp.gatsbyImageData} />
                        </div>
                        <div className="description-wrapper">
                          <div className="quotes">“</div>
                          <div className="description">
                            <TextTruncate
                              line={3}
                              truncateText="..."
                              text={testimonial?.content?.replaceAll(/<[^>]+>/g, '').replaceAll('&#8217;', '’').replaceAll('&#8221;', '”').replaceAll('&#8220;', '“')}
                              textTruncateChild={<Link to={`/testimonials/#${testimonial?.slug}`}>read more</Link>}
                            />
                          </div>
                        </div>
                        <h4><Link to={`/testimonials/#${testimonial?.slug}`}>{testimonial?.title}</Link></h4>
                      </div>
                    </SwiperSlide>
                  )
                })}
              </Swiper>
              <Link className="button outline outline-white" to="/testimonials">View All</Link>
            </div>
            <div className="recent-posts">
              <h3 className="title">Recent Posts</h3>
              {posts?.map(post => {
                return(
                  <div className="recent-post">
                    <h4 className="title"><Link to={post?.uri}>{post?.title}</Link></h4>
                    <div className="date">{post?.date}</div>
                  </div>
                )
              })}
            </div>
          </aside>
	      )
      }}
    />
  )
}
